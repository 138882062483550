var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{staticClass:"section section-secondary jpadding jpadding-20",attrs:{"id":"dashboard-blog"}},[(_vm.isLoading)?_c('div',{key:"spinner",staticClass:"text-center loading-container"},[_c('i',{staticClass:"text-blue fad fa-spinner-third fa-spin fa-3x"}),_c('p',{staticClass:"mt-3"},[_vm._v("Loading blog articles...")])]):(_vm.showError)?_c('div',{key:"error",staticClass:"jcard-content text-center"},[_vm._m(0),_c('p',[_vm._v(" Unable to fetch blog articles from the servers at the moment. ")])]):_vm._e(),(!_vm.isLoading && !_vm.showError)?_c('section',{key:"content",staticClass:"articles"},[_c('div',{staticClass:"blog-categories-list mt-0"},[_c('span',{staticClass:"mr-2"},[_vm._v("Discover our blog:")]),_c('a',{staticClass:"mr-2",class:{ active: _vm.categoryTabs[0] },attrs:{"href":"#"},on:{"click":function($event){return _vm.changeCategory(0, 0)}}},[_vm._v("All")]),_vm._l((_vm.categories),function(category,index){return _c('a',{key:category.id,staticClass:"mr-2",class:{ active: _vm.categoryTabs[index + 1] },attrs:{"href":"#"},on:{"click":function($event){return _vm.changeCategory(category.id, index)}}},[_vm._v(_vm._s(category.name))])})],2),_c('h5',{staticClass:"articles-title"},[_vm._v(_vm._s(_vm.dipslayCategoryName)+" Articles")]),_c('div',{staticClass:"articles-list"},[_vm._l((_vm.allArticles),function(article){return _c('div',{key:article.id,staticClass:"jcard-article-h"},[_c('div',{staticClass:"jcard-article-image",style:({
              'background-image':
                'url(\'/api/blog/covers/' + article.link_name + '.png\')',
            })}),_c('div',{staticClass:"jcard-article-content"},[_c('h5',{staticClass:"mb-0"},[_vm._v(_vm._s(article.title))]),_c('div',{staticClass:"detail-sm"},[_vm._v(" "+_vm._s(_vm.getCategoryName(article.categoryId))+" | "+_vm._s(article.reading_time)+" min read ")]),_c('div',{staticClass:"detail-sm"},[_vm._v(" "+_vm._s(_vm.getFormattedDate(article.created_date))+" ")]),_c('div',{staticClass:"detail-sm"},[(article.featured==1)?_c('i',{staticClass:"fa fa-star text-orange mr-2"}):_vm._e(),(article.featured_home==1)?_c('i',{staticClass:"fa fa-star text-blue mr-2"}):_vm._e()])]),_c('div',{staticClass:"jcard-article-btns"},[_c('b-dropdown',{attrs:{"right":"","variant":"link","toggle-class":"jbtn jbtn-icon jbtn-icon-link","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('i',{staticClass:"fa fa-ellipsis-v"})]},proxy:true}],null,true)},[_c('b-dropdown-item',{attrs:{"to":{
                  name: 'AddEditArticle',
                  params: { mode: 'edit' },
                  query: { id: article.id },
                }}},[_c('i',{staticClass:"fal fa-pencil mr-2"}),_vm._v(" Edit article")]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.unpublishBlogArticle(article.id)}}},[_c('i',{staticClass:"fal fa-times-circle mr-2"}),_vm._v(" Unpublish article")]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.showDeleteConfirmDialog(article)}}},[_c('i',{staticClass:"fal fa-trash mr-2"}),_vm._v(" Delete article")]),_c('b-dropdown-item',{attrs:{"href":'https://talkii.app/blog/' + article.link_name,"target":"_blank"}},[_c('i',{staticClass:"fal fa-external-link mr-2"}),_vm._v(" Show article")])],1)],1)])}),(_vm.showEmpty)?_c('div',{staticClass:"articles-empty detail"},[_vm._m(1)]):_vm._e()],2)]):_vm._e()]),_c('b-modal',{ref:"cofirm-delete-modal",attrs:{"hide-header":"true","hide-footer":"true","id":"cofirm-delete-modal","centered":"","title":"BootstrapVue"}},[_c('div',{staticClass:"jdialog-top"},[_c('div',{staticClass:"close-container"},[_c('h3',[_c('strong',[_vm._v("Confirm delete")])]),_c('span',{staticClass:"ml-auto jclose",on:{"click":function($event){return _vm.$bvModal.hide('cofirm-delete-modal')}}},[_c('i',{staticClass:"fa fa-times "})])]),_c('p',[_c('small',[_vm._v("Are you sure you want to delete this article?")])])]),_c('div',{staticClass:"jdialog-main"},[_c('p',[_vm._v(_vm._s(_vm.articleToDelete.title))])]),_c('div',{staticClass:"jdialog-bottom with-cancel"},[_c('button',{staticClass:"jbtn jbtn-sm",on:{"click":function($event){return _vm.$bvModal.hide('cofirm-delete-modal')}}},[_c('i',{staticClass:"fa fa-times"}),_vm._v(" Cancel ")]),_c('button',{staticClass:"jbtn jbtn-sm jbtn-red",on:{"click":_vm.deleteBlogArticle}},[(_vm.isSavingDialog)?_c('span',{key:"spinner"},[_c('i',{staticClass:"fad fa-spinner-third fa-spin"})]):_c('span',{key:"button"},[_c('i',{staticClass:"fa fa-trash"}),_vm._v(" Delete")])])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h5',{staticClass:"mb-3"},[_c('i',{staticClass:"fad fa-exclamation-circle text-blue fa-3x"}),_c('br')])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('i',{staticClass:"fad fa-telescope text-blue mr-2"}),_vm._v(" No articles found. ")])}]

export { render, staticRenderFns }