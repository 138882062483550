<template>
  <div>
    <section
      id="dashboard-blog"
      class="section section-secondary jpadding jpadding-20"
    >
      <!--------------------- loading --------------------->
      <div v-if="isLoading" key="spinner" class="text-center loading-container">
        <i class="text-blue fad fa-spinner-third fa-spin fa-3x"></i>
        <p class="mt-3">Loading blog articles...</p>
      </div>
      <!--------------------- error --------------------->
      <div v-else-if="showError" key="error" class="jcard-content text-center">
        <h5 class="mb-3">
          <i class="fad fa-exclamation-circle text-blue fa-3x"></i><br />
        </h5>
        <p>
          Unable to fetch blog articles from the servers at the moment.
        </p>
      </div>

      <section v-if="!isLoading && !showError" key="content" class="articles">
        <!--------------------- categories --------------------->
        <div class="blog-categories-list mt-0">
          <span class="mr-2">Discover our blog:</span>
          <a
            href="#"
            @click="changeCategory(0, 0)"
            v-bind:class="{ active: categoryTabs[0] }"
            class="mr-2"
            >All</a
          >

          <a
            href="#"
            v-for="(category, index) in categories"
            :key="category.id"
            @click="changeCategory(category.id, index)"
            v-bind:class="{ active: categoryTabs[index + 1] }"
            class="mr-2"
            >{{ category.name }}</a
          >
        </div>

        <!--------------------- articles --------------------->
        <h5 class="articles-title">{{ dipslayCategoryName }} Articles</h5>
        <div class="articles-list">
          <div
            v-for="article in allArticles"
            :key="article.id"
            class="jcard-article-h"
          >
            <div
              class="jcard-article-image"
              v-bind:style="{
                'background-image':
                  'url(\'/api/blog/covers/' + article.link_name + '.png\')',
              }"
            ></div>
            <div class="jcard-article-content">
              <h5 class="mb-0">{{ article.title }}</h5>
              <div class="detail-sm">
                {{ getCategoryName(article.categoryId) }} |
                {{ article.reading_time }} min read
              </div>
              <div class="detail-sm">
                {{ getFormattedDate(article.created_date) }}
              </div>
              <div class="detail-sm">
                <i v-if="article.featured==1" class="fa fa-star text-orange mr-2"></i
                ><i v-if="article.featured_home==1" class="fa fa-star text-blue mr-2"></i>
              </div>
            </div>
            <div class="jcard-article-btns">
              <b-dropdown
                right
                variant="link"
                toggle-class="jbtn jbtn-icon jbtn-icon-link"
                no-caret
              >
                <template #button-content>
                  <i class="fa fa-ellipsis-v"></i>
                </template>
                <b-dropdown-item
                  :to="{
                    name: 'AddEditArticle',
                    params: { mode: 'edit' },
                    query: { id: article.id },
                  }"
                  ><i class="fal fa-pencil mr-2"></i> Edit
                  article</b-dropdown-item
                >
                <b-dropdown-item @click="unpublishBlogArticle(article.id)"
                  ><i class="fal fa-times-circle mr-2"></i> Unpublish
                  article</b-dropdown-item
                >
                <b-dropdown-item @click="showDeleteConfirmDialog(article)"
                  ><i class="fal fa-trash mr-2"></i> Delete
                  article</b-dropdown-item
                >
                <b-dropdown-item
                  :href="'https://talkii.app/blog/' + article.link_name"
                  target="_blank"
                  ><i class="fal fa-external-link mr-2"></i> Show
                  article</b-dropdown-item
                >
              </b-dropdown>
            </div>
          </div>
          <div v-if="showEmpty" class="articles-empty detail">
            <p>
              <i class="fad fa-telescope text-blue mr-2"></i> No articles found.
            </p>
          </div>
        </div>
      </section>
    </section>

    <!-- ----- confirm delete modal --------------------------------- -->
    <b-modal
      hide-header="true"
      hide-footer="true"
      id="cofirm-delete-modal"
      ref="cofirm-delete-modal"
      centered
      title="BootstrapVue"
    >
      <div class="jdialog-top">
        <div class="close-container">
          <h3><strong>Confirm delete</strong></h3>
          <span
            @click="$bvModal.hide('cofirm-delete-modal')"
            class="ml-auto jclose"
            ><i class="fa fa-times "></i
          ></span>
        </div>
        <p>
          <small>Are you sure you want to delete this article?</small>
        </p>
      </div>
      <div class="jdialog-main">
        <p>{{ articleToDelete.title }}</p>
      </div>
      <div class="jdialog-bottom with-cancel">
        <button
          @click="$bvModal.hide('cofirm-delete-modal')"
          class="jbtn jbtn-sm"
        >
          <i class="fa fa-times"></i> Cancel
        </button>
        <button @click="deleteBlogArticle" class="jbtn jbtn-sm jbtn-red">
          <span v-if="isSavingDialog" key="spinner"
            ><i class="fad fa-spinner-third fa-spin"></i
          ></span>
          <span v-else key="button"><i class="fa fa-trash"></i> Delete</span>
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
// import C from "@/constants";
import toast from "@/assets/js/toast";
import axios from "axios";

export default {
  name: "ArticlesList",
  data() {
    return {
      isLoading: true,
      showError: false,
      showEmpty: false,
      isSavingDialog: false,
      dipslayCategoryId: 0,
      dipslayCategoryIndex: 0,
      dipslayCategoryName: "All",

      popularArticles: null,
      allArticles: null,
      categories: null,
      articleToDelete: { id: "", title: "" },
      activeCatergoryTab: 0,
      categoryTabs: [],
    };
  },
  methods: {
    getArticles(cat) {
      this.showEmpty = false;
      axios
        .post("/api/blog/functions-blog.php", {
          request: "get_articles_by_category",
          categoryId: cat,
        })
        .then((response) => {
          console.log(response.data);
          this.allArticles = response.data;
          if (!Array.isArray(this.allArticles) || !this.allArticles.length) {
            // toast.error("Error loading articles from database.");
            // this.showError = true;
            this.showEmpty = true;
          }
          // this.getCategories();
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          toast.error(error.message);
          this.isLoading = false;
          this.showError = true;
        });
    },
    getCategories() {
      axios
        .post("/api/blog/functions-blog.php", {
          request: "get_categories",
        })
        .then((response) => {
          console.log(response.data);
          this.categories = response.data;
          if (!Array.isArray(this.categories) || !this.categories.length) {
            toast.error("Error loading categories from database.");
            this.isLoading = false;
            this.showError = true;
          }
          //set categories tabs
          this.categoryTabs = [];
          for (var i = 0; i < this.categories.length; i++) {
            this.categoryTabs.push(false);
          }
          this.changeCategory(
            this.dipslayCategoryId,
            this.dipslayCategoryIndex
          );
        })
        .catch((error) => {
          console.error(error);
          toast.error(error.message);
          this.isLoading = false;
          this.showError = true;
        });
    },
    unpublishBlogArticle(id) {
      axios
        .post("/api/blog/functions-blog.php", {
          request: "publish_article",
          id: id,
          published: false,
        })
        .then((response) => {
          console.log(response.data);
          //check if valid response, check status
          if (response.data === "OK") {
            this.getArticles(this.dipslayCategoryId);
            toast.success("Article unpublished and added to drafts.");
          } else {
            toast.error(response.data);
          }
        })
        .catch((error) => {
          console.error(error);
          toast.error(error.message);
        });
    },
    showDeleteConfirmDialog(article) {
      this.articleToDelete = article;
      this.$refs["cofirm-delete-modal"].show();
    },
    deleteBlogArticle() {
      this.isSavingDialog = true;
      axios
        .post("/api/blog/functions-blog.php", {
          request: "delete_article",
          id: this.articleToDelete.id,
        })
        .then((response) => {
          console.log(response.data);
          //check if valid response, check status
          if (response.data === "OK") {
            toast.success("Article deleted.");
            this.getArticles(this.dipslayCategoryId);
            this.$refs["cofirm-delete-modal"].hide();
          } else {
            toast.error(response.data);
          }
          this.isSavingDialog = false;
        })
        .catch((error) => {
          console.error(error);
          toast.error(error.message);
          this.isSavingDialog = false;
        });
    },
    changeCategory(catId, index) {
      this.dipslayCategoryId = catId;
      // this.dipslayCategoryIndex=index;
      //set active
      var indexToSet = 0;
      if (this.dipslayCategoryId == 0) {
        this.dipslayCategoryName = "All";
      } else {
        // index = this.categories.findIndex((x) => x.id === catId) + 1;
        this.dipslayCategoryName = this.categories[index].name;
        indexToSet = index + 1;
      }
      this.$set(this.categoryTabs, this.activeCatergoryTab, false);
      this.$set(this.categoryTabs, indexToSet, true);
      this.activeCatergoryTab = indexToSet;
      //load aticles
      this.getArticles(this.dipslayCategoryId);
    },
    getCategoryName(id) {
      return this.categories.find((x) => x.id === id).name;
    },
    getFormattedDate(dateStr) {
      var d = new Date(dateStr);
      var months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      return months[d.getMonth()] + " " + d.getDate() + ", " + d.getFullYear();
    },
  },
  created() {
    //this.watch('$route.query.c', this.getArticles)
    // if (this.$route.query.c != null && this.$route.query.c != "")
    //   this.dipslayCategoryId = this.$route.query.c;
    this.getCategories();
    // this.changeCategory(this.dipslayCategoryId);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/blog.scss";
</style>
